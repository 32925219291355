import { lazy } from "react";
import AppIndexPage from "../chunk/jacket";
import { Navigate } from "react-router-dom";
const DepositPage = lazy(() => import('../chunk/wallet/Deposit'))
const PaymentMethod = lazy(() => import('../chunk/wallet/Payment'))
const PayCodePage = lazy(() => import('../chunk/wallet/PayCode'))
const PCodeQueryPage = lazy(() => import('../chunk/query/paycode/PCQuery'))
const CurrencyPage = lazy(() => import('../chunk/wallet/Currency'))
const PerAccInfo = lazy(() => import('../chunk/wallet/PersonalAccInfo'))
const ResultPage = lazy(() => import('../chunk/wallet/Result'))
const WalletListPage = lazy(() => import('../chunk/wallet/WalletList'))
const QtChartPage = lazy(() => import('../chunk/charts/QtCharts'))
const Error404 = lazy(() => import('../chunk/error/Error404'))
const SlotsPage = lazy(() => import('../chunk/slots/Slots'))
const TopupPage = lazy(() => import('../chunk/wallet/Topup'))
const CheckstandPage = lazy(() => import('../chunk/wallet/Checkstand'))
const GuidePage = lazy(() => import('../chunk/guide/GuidePage'))
const AgreementWebPage = lazy(() => import('../chunk/wallet/Agreement'))
const PolicyPage = lazy(() => import('../chunk/wallet/Policy'))


const routes = [
    {
        path: '/',
        element: <AppIndexPage />,
        children: [
            {
                path: '/',
                element: <Navigate to={'qt'} />
            },
            // {
            //     path: 'wallet/deposit',
            //     element: <DepositPage />,
            //     meta: {
            //         title: 'Deposit',
            //     },
            // },
            {
                path: 'qt',
                element: <GuidePage />,
                meta: {
                    title: 'QT Options',
                },
            },
            // {
            //     path: 'wallet/payment',
            //     element: <PaymentMethod />,
            //     meta: {
            //         title: 'Payment Method',
            //     },
            // },
            // {
            //     path: 'wallet/paycode',
            //     element: <PayCodePage />,
            //     meta: {
            //         title: 'Pay Code',
            //     },
            // },
            // {
            //     path: 'wallet/currency',
            //     element: <CurrencyPage />,
            //     meta: {
            //         title: 'Deposit Currency',
            //     },
            // },
            // {
            //     path: 'wallet/peraccinfo',
            //     element: <PerAccInfo />,
            //     meta: {
            //         title: 'Deposit',
            //     },
            // },
            // {
            //     path: 'wallet/checkstands',
            //     element: <CheckstandPage />,
            //     meta: {
            //         title: 'Checkstands',
            //     },
            // },
            // {
            //     path: 'wallet/result',
            //     element: <ResultPage />,
            //     meta: {
            //         title: 'Deposit',
            //     },
            // },
            // {
            //     path: 'wallet/walletlist',
            //     element: <WalletListPage />,
            //     meta: {
            //         title: 'Deposit',
            //     },
            // },
            // {
            //     path: 'query/pcquery',
            //     element: <PCodeQueryPage />,
            //     meta: {
            //         title: 'Pay Code Query',
            //     },
            // },
            // {
            //     path: 'qtcharts',
            //     element: <QtChartPage />,
            //     meta: {
            //         title: 'Pay Code Query',
            //     },
            // },
            // {
            //     path: 'wallet/topup',
            //     element: <TopupPage />,
            //     meta: {
            //         title: 'Top Up',
            //     },
            // },
            // {
            //     path: 'wallet/agreement',
            //     element: <AgreementWebPage />,
            //     meta: {
            //         title: 'Agreement',
            //     },
            // },
            // {
            //     path: 'wallet/policy',
            //     element: <PolicyPage />,
            //     meta: {
            //         title: 'PolicyPage',
            //     },
            // },
            // {
            //     path: 'slots',
            //     element: <SlotsPage />,
            //     meta: {
            //         title: 'slots download page',
            //     },
            // },
            {
                path: '*',
                element: <Error404 />,
                meta: {
                    title: 'Error 404',
                },
            }
        ]
    }
]

export default routes