import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { getAppInfo } from "../lever/jsBridge";
import { useTranslation } from "react-i18next";
import router from "../link/links";
import "../config/language/index";

const AppIndex: React.FC = () => {
    const { i18n } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const paramObj: Record<string, any> = {};
    if (urlParams) {
        urlParams.forEach(function (value, key) {
            paramObj[key] = value;
        });
    }
    
    useEffect(() => {
        i18n.changeLanguage(paramObj['lang']);
    }, [paramObj['lang']]);

    return <RouterProvider router={router} />;
};

export default React.memo(AppIndex);
