import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cn from "./cn_h5.json";
import en from "./en_h5.json";
import idn from './idn_h5.json'

const resources = {
    en: {
        translation: en,
    },
    cn: {
        translation: cn,
    },
    idn: {
        translation: idn,
    },
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
